.top-navbar{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 20px;
}
.nav-dropdown-profile-img{
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 20px;
}
.profile-img{
    width: 2rem;
}
.dropdown-toggle::after{
    display: none;
}