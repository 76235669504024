.login-left{
    height: 100vh;
    background-color: #213046;
}

.login-right{
    height: 100vh;
    background-image: url('../../assets/background_img.png');
}
.login-form{
    width: 300px;
}
.submit-btn{
    display: block !important;
    margin: auto;
    border: none !important;
    background-color: #ff4400 !important;
}
