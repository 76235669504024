.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

ul{
  margin: 0px;
  padding: 0px;
}

a{
  text-decoration: none;
}

.App-link {
  color: #61dafb;
}

.wrapper {
  display: flex;
  min-height: 100vh;
}

.side-wrapper {
  width: 14rem;
  background-color: #213046;
  color: #fff;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.content-wrapper {
  flex: 1;
  background-color: #f8f9fa;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
}

.content-top-wrapper {
  /* height: 70px; */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #00000029;
}
.orange-clr{
  color: #fc8006;
}
.f-14{
  font-size: 14px;
}
.f-18{
  font-size: 18px;
}
.f-28{
  font-size: 28px;
}
.f-24{
  font-size: 24px;
}
.f-21{
  font-size: 21px;
}
.main-heading-pages{
  font-weight: bold;
  font-size: 2rem;
}
.form-label {
  font-weight: bold;
}
.css-hsi95o-MuiTableRow-root td {
  text-align: center;
}

.css-1w86f15,
.css-tucewo {
  display: flex;
  justify-content: center !important;
}
.custom_table_css table  thead tr th{
vertical-align:middle !important;
text-align:center !important;
text-transform:uppercase;
color:#000;
font-size:13px;
padding:8px;	
}
.custom_table_css table tbody tr td{
vertical-align:middle !important;
text-align:center !important;
text-transform:uppercase;
color:#000;
font-size:13px;
padding:8px;
border-bottom: 1px solid #ddd;	
}

.color-orange{
  color: #fc8006 !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}