.sidebar-nav {
    padding: 1rem 0;
    flex: 1 1 auto;
    overflow-y: auto;
}

a.sidebar-link {
    padding: 11px 20px;
    color: #fff;
    display: block;
    font-size: 12px;
    white-space: nowrap;
    text-decoration: none;
}

.sidebar-link span {
    font-size: 1rem;
    color: #fff;
}

.sidebar-link.active span {
    color: #fff;
    font-weight: bold;
}

.sidebar-link i {
    margin-right: .75rem;
}

a.sidebar-link:hover {
    background: linear-gradient(90deg, rgba(178, 142, 250, .2), #fff);
    color: #fff;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.sidebar-link.active {
    background: linear-gradient(90deg, rgba(178, 142, 250, .2), #fff);
    color: #fff;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.sidebar-item {
    position: relative;
}

.snb-icon {
    font-size: 1.2rem;
}


